import React, { useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Box, Grid, Container, Stack } from '@mui/material'
import { motion } from 'framer-motion'
import { Icon } from '@iconify/react'
import linkedinIcon from '@iconify/icons-logos/linkedin-icon'
import githubIcon from '@iconify/icons-logos/github'
import twitterIcon from '@iconify/icons-logos/twitter'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from "@vercel/speed-insights/react"
import './styles.css'
import { App } from './App'
import SlidingText from './slidingtext'
import Bottomslider from './bottomslider'
import About from './pages/about'
import LinksSection from './components/LinksSection'
import Skills from './components/Skills'
import Interests from './components/Interests'

const isNotMobile = window.screen.width > 700

function Root() {
  const iframeStyle = useMemo(() => ({
    border: "1px solid #777",
    maxWidth: isNotMobile ? "750px" : "85%",
    width: !isNotMobile ? "85%" : "100%",
    display: "block",
    margin: "0 auto",
  }), [])

  return (
    <div style={{ 
      position: 'relative', 
      display: 'flex', 
      flexDirection: 'column' 
    }}>
      {/* First section with reduced spacing */}
      <div style={{ minHeight: '80vh' }}>
        <SlidingText />
        <div className="appRender" 
          style={{ 
            width: "100vw", 
            height: "50vh", 
            minHeight: "400px", 
            marginBottom: "2vh" 
          }}>
          <Analytics/>
          <App />
          <SpeedInsights/>
        </div>
        <Bottomslider />
      </div>

      {/* Portfolio section */}
      <motion.div
        className="Portfolio"
        initial={{ x: '-100vw' }}
        animate={{ x: 0 }}
        transition={{ type: 'Spring', stiffness: 50, delay: 0.5, duration: 1 }}
        style={{ width: '100%', marginTop: '2vh' }}
      >
        <Box sx={{ flexGrow: 1, padding: isNotMobile ? '2rem' : '1rem' }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <iframe
                src="https://indd.adobe.com/embed/c4eb285c-7b4f-49ee-bc38-5926bed0d74d?startpage=1&allowFullscreen=true"
                title="Portfolio"
                width="100%"
                height={isNotMobile ? "371px" : "300px"}
                style={iframeStyle}
                frameBorder="0"
                allowFullScreen
              />
            </Grid>
          </Grid>
        </Box>
      </motion.div>

      {/* Additional content section */}
      <div>
        <Box sx={{ flexGrow: 1, padding: isNotMobile ? '2rem' : '1rem' }}>
          <Grid container spacing={2} sx={{ minHeight: '1000px' }}>

            {/* Left column - LinksSection */}
            <Grid item xs={12} md={6} sx={{ height: '100%', display:'flex' }}>
              <Box sx={{width: '100%', height:'100%'}}>
              <LinksSection />
              </Box>
            </Grid>
            
            {/* Right column - About and Skills */}
            <Grid item xs={12} md={6} sx={{ height: '100%' }}>
              <Grid 
                container 
                direction="column" 
                spacing={2}
                sx={{ height: '100%' }}
              >
                <Grid item xs={12} sx={{ height: '33' }}>
                  <About
                    resumeBasicInfo={{
                      description_header: "Hi",
                      description: "Hi—I'm Alejandro Hernandez. Extroverted, enthusiastic, and friendly, I study both Pure Mathematics and Computer Science (first love still is physics!) with nearly a decade of combined work experience in software development and IT. Bilingual (Spanish), confident and experienced in public speaking. My personal philosophy seeks compassionate/constructive solutions to critical issues with clients. Detail-oriented writer. Autodidact concurrently enthusiastic toward learning new skills from others. When not working, can often be found either surfing and cycling at the beach!",
                      section_name: {
                        about: "About me"
                      }
                    }}
                    sharedBasicInfo={{
                      image: "null"
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ height: '33%' }}>
                <Skills skills={<>
                      <strong>Programming Languages:</strong> C/C++, Python, R, MATLAB, Java, JavaScript/TypeScript, HTML5, LaTeX, Visual Basic, Wolfram Language, SQL, Embedded C
                      <br/><br/>
                      <strong>Cloud & DevOps:</strong> Google Cloud Platform (GCP), Firebase, Azure, AWS, DigitalOcean (DO), Docker, GitLab CI/CD, Git
                      <br/><br/>
                      <strong>Embedded Systems:</strong> STM32 (F-series MCUs), TI AM263x, TI BQ796x ASICs, JTAG, SWD, ARM architectures, CUDA, FPGA (LX7... this one is REALLY cool), PCB Design (Altium), CAN/I2C/SPI/QSPI/UART protocols, RTOS, ESP32, NVIDIA Jetson (strong opinions on this one)
                      <br/><br/>
                      <strong>Scientific Tools:</strong> IEEE 488.2, SCPI, VISA, National Instruments (both hardware operation and low-level software), Lock-in Amplifiers, Nanovoltmeters, Signal Generators, Oscilloscopes, Spectrum Analyzers
                      <br/><br/>
                      <strong>Autonomous Systems:</strong> AirSim, CARLA simulation, SLAM, PnP, Graph/Pathfinding algos, Sensor fusion, PX4, ROS, Renode, Verilator, RTMaps
                      <br/><br/>
                      <strong>AI/ML:</strong> PyTorch, TensorFlow, LTCN + Neural ODEs (R. Hasani + CSAIL's work), Pandas
                      <br/><br/>
                      <strong>Development:</strong> REST APIs, React, Material-UI, Framer Motion, Selenium
                      <br/><br/>
                      <strong>Enterprise:</strong> ERPNext (modifying codebase, etc), Salesforce, Tableau, TDAmeritrade API, Excel (Advanced)
                      <br/><br/>
                      <strong>Additional:</strong> Self-taught in derivatives trading and financial mathematical methods (dynamic hedging). International Data Engineering & Science Association member since 2017. Member of the Society of Hispanic Professional Engineers. Member of the Society of Automotive Engineers.
                    </>} />
                </Grid>
                <Grid item xs={12} sx={{height: '33%'}}>
                  <Interests interests={
                    <>
                    I am deeply passionate about the development of autonomous systems and robotics, with a particular focus on real-time distributed architectures and safety-critical applications. Through my work founding and leading <strong>Highlander Racing's</strong> autonomous racing platform, I've developed extensive experience in sensor fusion, state estimation (with multi-hypotheses tracking), and autonomous navigation systems. I'm constantly drawn to the challenges of optimizing embedded systems and developing robust control architectures that can operate reliably in complex environments. My research experience in the Barsukov Group has also strengthened my interest in high-precision data acquisition and signal processing, while my work on simulation frameworks has shown me the crucial role of comprehensive testing methodologies in autonomous system development (with major cost benefits!). As of late, I've been particularly fascinated by the integration of AI/ML applications in robotics and the optimization of edge computing solutions for real-time operations. 
                  <br /> <br />
                  <strong>Currently,</strong> I'm focused on advancing autonomous navigation systems, optimizing distributed computing architectures for edge, and developing novel sim-based validation methods. My ultimate goal is to develop robust, safe autonomous systems that can operate effectively in challenging real-world environments with a stack optimized from bare metal through cutting edge theory (LTCNs, etc). I believe in combining rigorous first principles with innovative approaches to push the boundaries of what's possible in autonomous systems and robotics. Check out Galois Research for my latest work."
                  </>
                  } />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Social Links */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ marginTop: 4, marginBottom: 4, padding: '2rem' }}
        >
          <a href="https://www.linkedin.com/in/hernan-tech" target="_blank" rel="noopener noreferrer">
            <Icon icon={linkedinIcon} width="32" height="32" />
          </a>
          <a href="https://github.com/hernantech" target="_blank" rel="noopener noreferrer">
            <Icon icon={githubIcon} width="32" height="32" />
          </a>
          <a href="https://twitter.com/cokeandmath" target="_blank" rel="noopener noreferrer">
            <Icon icon={twitterIcon} width="32" height="32" />
          </a>
        </Stack>
      </div>
    </div>
  )
}

createRoot(document.getElementById('root')).render(
  <BrowserRouter><Root /></BrowserRouter>
)