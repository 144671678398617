// LoadingScreen.js
import React from 'react';
import { motion } from 'framer-motion';
import { Typography, Box } from '@mui/material';

const LoadingScreen = ({ progress }) => {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Box
          position="relative"
          display="inline-flex"
          sx={{ width: 100, height: 100 }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              background: `conic-gradient(#cd81f1 ${progress}%, transparent ${progress}%)`,
              transform: 'rotate(-90deg)'
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="caption" component="div" color="#ffffff">
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        </Box>
      </motion.div>
      <Typography variant="h6" style={{ color: '#fff', marginTop: '20px' }}>
        Loading...
      </Typography>
    </motion.div>
  );
};

export default LoadingScreen;