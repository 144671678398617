import React from "react";
import { Typography, Box, Paper } from '@mui/material';

const About = ({ resumeBasicInfo }) => {
  const { description_header, description } = resumeBasicInfo;

  return (
    <Paper
      elevation={3}
      sx={{
        height: '100%',
        borderRadius: 2,
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        p: 3
      }}
    >
      <Typography variant="h4" gutterBottom>{description_header}</Typography>
      <Typography
        variant="body1"
        sx={{
          flex: 1,
          overflow: 'auto'
        }}
      >
        {description}
      </Typography>
    </Paper>
  );
};

export default About;