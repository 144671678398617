import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, Paper, CircularProgress } from '@mui/material'
import { motion, AnimatePresence } from 'framer-motion'
import { Icon } from '@iconify/react'
import githubIcon from '@iconify/icons-logos/github'
import { useNavigate } from 'react-router-dom'

const LinksSection = () => {
  const navigate = useNavigate();
  const [imageIndices, setImageIndices] = useState({
    foundersInc: 0,
    ieeelecture: 0,
    devops: 0
  });
  const [loadError, setLoadError] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [currentImages, setCurrentImages] = useState({});
  const imageElementsRef = useRef({});

  const links = [
    {
      id: 'foundersInc',
      title: 'Founders Inc. AI Hackathon',
      url: 'https://github.com/hernantech/finc-hackathon-repo',
      description: 'Computer-Vision only AI-powered turtlebot (we removed the LIDAR sensor) built with the aim of helping blind individuals. Can converse, identify objects, navigate autonomously towards objects, and retrieve them.',
      images: [
        '/7D0E3719-1A76-4F24-B3BA-3B58C626DD4C_1_102_o.jpeg',
        '/hackathon_group.jpeg'
      ],
      offset: 0,
      github: 'https://github.com/hernantech/finc-hackathon-repo'
    },
    {
      title: 'EcoCar UCR',
      url: 'https://ecocar.engr.ucr.edu/subteams#connected-and-automated-vehicl',
      description: 'Member of the Connected and Autonomous Vehicle team. Duties Summer/Fall 2024 include Sensor Fusion and assisting in CI/CD pipelines (Github actions, Gitlab Runners, MATLAB/Simulink, etc)',
      videoUrl: '/18023996912421685.mp4',
      github: 'https://github.com/XuanpengZhao/EcoCAR-CAV'
    },
    {
      id: 'lidar_fusion',
      title: 'EcoCar CAV Project',
      url: 'https://gist.github.com/hernantech/62b820c73aaf9128ddb21eaff4d39761',
      description: 'Low latency processsing (sub 5ms processing) of lidar. Expected completion date of December 2024.',
      videoUrl: '/lidar_videomini.mp4',
      github: 'https://github.com/hernantech/lidar0'
    },
    {
      id: 'devops',
      title: 'Formula + Autonomous DevOps',
      url: 'https://gist.github.com/hernantech/64f575d5bceec5b7bf0cf30cf7c8917d',
      description: 'DevOps and documentation for running formula team backend infrastructure. Includes Ansys sims, ngspice, ML model training, etc.\n',
      images: ['/server1.jpeg', '/server3.jpeg'],
      offset: 1000,
      github: 'https://gist.github.com/hernantech/64f575d5bceec5b7bf0cf30cf7c8917d'
    },
    {
      id: 'ieeelecture',
      title: 'IEEE Lecture',
      url: 'https://www.overleaf.com/read/ftbkyfgvtnxk#1b8fd2',
      description: 'Technical presentation on firmware validation methodologies and simulation frameworks. Demonstrated approaches for achieving 90% reduction in prototyping costs through sim-based validation, featuring Renode simulation for multi-node system validation and PX4-AirSim bridge implementation.\n',
      images: ['/ieeelecture.png', '/python_bridge.png'],
      offset: 2500,
      github: 'https://github.com/hernantech'
    },
    {
      id: 'galois',
      title: 'Galois Research',
      url: 'https://galoisresearch.com',
      description: 'Developing a unified hardware and software stack for OS-free distributed edge computing and autonomous agents. Primarily focused on developing dual-use LTCN/CNN based AI models and supporting toolchains. Working towards EW+drone use cases.',
      image: '/galois.jpeg',
      github: 'https://github.com/Galois-Research'
    }
  ];

  useEffect(() => {
    const imageObjects = {};
    const loadPromises = [];

    links.forEach(link => {
      if (link.images) {
        imageObjects[link.id] = [];
        imageElementsRef.current[link.id] = [];
        
        link.images.forEach((src, index) => {
          const img = new Image();
          const loadPromise = new Promise((resolve, reject) => {
            img.onload = () => {
              imageObjects[link.id][index] = img;
              imageElementsRef.current[link.id][index] = img;
              resolve();
            };
            img.onerror = reject;
          });
          loadPromises.push(loadPromise);
          img.src = src;
        });
      } else if (link.image) {
        const img = new Image();
        const loadPromise = new Promise((resolve, reject) => {
          img.onload = () => {
            imageObjects[link.id] = [img];
            imageElementsRef.current[link.id] = [img];
            resolve();
          };
          img.onerror = reject;
        });
        loadPromises.push(loadPromise);
        img.src = link.image;
      }
    });

    Promise.all(loadPromises)
      .then(() => {
        setCurrentImages(imageObjects);
        setImagesLoaded(true);
      })
      .catch(error => {
        console.error('Error loading images:', error);
        setLoadError(true);
      });

    return () => {
      Object.values(imageElementsRef.current).forEach(images => {
        images.forEach(img => {
          img.src = '';
        });
      });
    };
  }, []);

  useEffect(() => {
    if (!imagesLoaded) return;

    const intervals = links
      .filter(link => link.images && link.images.length > 1)
      .map(link => {
        const timeout = setTimeout(() => {
          const interval = setInterval(() => {
            setImageIndices(prev => ({
              ...prev,
              [link.id]: (prev[link.id] + 1) % currentImages[link.id].length
            }));
          }, 5000);
          return () => clearInterval(interval);
        }, link.offset || 0);
        return () => clearTimeout(timeout);
      });

    return () => intervals.forEach(cleanup => cleanup?.());
  }, [imagesLoaded, currentImages]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5, ease: "easeOut" } }
  };

  return (
    <Paper elevation={3} sx={{ 
      borderRadius: 2, 
      backgroundColor: '#fff', 
      p: 3,
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Typography variant="h4" gutterBottom>Additional Featured Projects</Typography>
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <motion.div variants={containerVariants} initial="hidden" animate="visible">
          {links.map((link, index) => (
            <motion.div key={index} variants={itemVariants}>
              <Box sx={{ mb: index !== links.length - 1 ? 3 : 0, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, backgroundColor: 'rgba(0,0,0,0.02)', borderRadius: 1, overflow: 'hidden' }}>
                <Box sx={{ position: 'relative', width: { xs: '100%', sm: '160px' }, minWidth: { xs: 'auto', sm: '160px' }, height: { xs: '160px', sm: '160px' } }}>
                  {!imagesLoaded && (link.images || link.image) ? (
                    <Box sx={{ 
                      width: '100%', 
                      height: '100%', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      backgroundColor: 'rgba(0,0,0,0.05)'
                    }}>
                      <CircularProgress />
                    </Box>
                  ) : link.videoUrl ? (
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    >
                      <source src={link.videoUrl} type="video/mp4" />
                    </video>
                  ) : link.images && currentImages[link.id] ? (
                    <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                      {currentImages[link.id].map((img, idx) => (
                        <motion.img
                          key={`${link.id}-${idx}`}
                          src={img.src}
                          initial={{ opacity: 0 }}
                          animate={{ 
                            opacity: imageIndices[link.id] === idx ? 1 : 0,
                            zIndex: imageIndices[link.id] === idx ? 1 : 0
                          }}
                          transition={{ duration: 0.5 }}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            pointerEvents: 'none'
                          }}
                        />
                      ))}
                    </Box>
                  ) : link.image && currentImages[link.id] ? (
                    <img
                      src={currentImages[link.id][0].src}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                      alt={link.title}
                    />
                  ) : loadError ? (
                    <Box sx={{ 
                      width: '100%', 
                      height: '100%', 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center',
                      backgroundColor: 'rgba(255,0,0,0.05)'
                    }}>
                      <Typography variant="body2" color="error">Failed to load image</Typography>
                    </Box>
                  ) : null}
                </Box>
                <Box sx={{
                  p: 2,
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <Box>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1
                    }}>
                      <Typography
                        variant="h6"
                        component="a"
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: 'primary.main',
                          textDecoration: 'none',
                          '&:hover': { textDecoration: 'underline' }
                        }}
                      >
                        {link.title}
                      </Typography>
                      <motion.a
                        href={link.github}
                        target="_blank"
                        rel="noopener noreferrer"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Icon icon={githubIcon} style={{ fontSize: '24px', cursor: 'pointer' }} />
                      </motion.a>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {link.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </motion.div>
          ))}
        </motion.div>
      </Box>
    </Paper>
  );
};

export default LinksSection;