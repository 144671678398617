import React from "react";
import "./slidestyle.css";
export default function Bottomslider() {
  return (
    <section className="centered-section group">
      <div className="bottom-slider-text">
        <span>Scroll down to see my portfolio</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="bottom-slider-icon pointer-events-none relative z-10 text-7xl text-black transition-all duration-700 ease-out"
        >
          <path d="M12 5v14" />
          <path d="m19 12-7 7-7-7" />
        </svg>
      </div>
    </section>
  );
}



