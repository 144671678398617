// src/useLoading.js
import { useState, useEffect } from 'react';
import { useProgress } from '@react-three/drei';
import * as THREE from 'three';

const useRealLoading = () => {
  const { progress: threeProgress } = useProgress();
  const [fontLoaded, setFontLoaded] = useState(false);
  const [threeReady, setThreeReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Load font
  useEffect(() => {
    const font = new FontFace(
      'Druk Wide Super',
      "url('./fonts/DrukWide-Super-Web.woff2')"
    );

    font.load().then(() => {
      document.fonts.add(font);
      setFontLoaded(true);
    }).catch(error => {
      console.error('Font loading error:', error);
      setFontLoaded(true);
    });
  }, []);

  // Calculate total progress
  const totalProgress = Math.floor(
    (threeProgress * 0.8) +  // 80% weight for Three.js assets
    (fontLoaded ? 20 : 0)    // 20% weight for font
  );

  // Monitor overall loading progress
  useEffect(() => {
    if (fontLoaded && threeReady && threeProgress === 100) {
      // Add a small delay to ensure smooth transition
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [fontLoaded, threeReady, threeProgress]);

  return { 
    progress: totalProgress, 
    isLoading,
    setThreeReady // Expose this to coordinate with ScenePreloader
  };
};

export default useRealLoading;