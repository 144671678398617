import React from "react";
import { Typography, Paper } from '@mui/material';

const Skills = ({ skills }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        height: '100%',
        borderRadius: 2,
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        p: 3
      }}
    >
      <Typography variant="h4" gutterBottom>Skills</Typography>
      <Typography
        variant="body1"
        sx={{
          flex: 1,
          overflow: 'auto'
        }}
      >
        {skills}
      </Typography>
    </Paper>
  );
};

export default Skills;